import * as React from "react";
import type { HeadFC } from "gatsby";
import "../styles/reset.css";
import "../styles/index.scss";
import Layout from "../components/Layout";
import Slab from "../components/Slab";
import Expander, { Separator } from "../components/Expander";
import Callout, { CalloutLeft } from "../components/Callout";
import CtaAidsVu from "../content/CtaAidsVu";

import Hero from "../images/hero-2.png";
//import HeroWebp from "../images/hero-2.webp";
import Lockup from "../images/retrospective-data@2x.png";
import LockupMobile from "../images/retrospective-data-mobile@2x.png";
import Endnotes from "../components/Endnotes";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
    <Layout>
      <Slab>
        <div className="flex flex-col md:flex-row ">
          <div className="md:w-3/4 bg-green">
            <div className="w-2/3 mr-auto h-3 md:h-5 bg-lime"></div>
            <div className="h-full flex justify-center items-center md:mb-10">
              <div className="w-1/8 md:w-1/6 shrink-0"></div>

              <h1 className="text-header-2 font-bold text-white my-12 mr-12 sm:mr-16 pb-3 md:pb-5">
                In the real-world setting, substantial decreases in US HIV
                diagnosis rates have occurred among people who have been
                prescribed PrEP vs not prescribed PrEP<sup>1</sup>
              </h1>
            </div>
          </div>
          <div className="md:w-1/4">
            <StaticImage
              src="../images/hero-2@2x.png"
              className="w-full h-40 md:h-full object-cover object-left-bottom"
              role="presentation"
              alt=""
            />
          </div>
        </div>
        <div className="pt-8 pb-4">
          <div className="flex">
            <div className="w-1/8 shrink-0"></div>
            <div className="text-header-2-alt mr-11 md:mr-28">
              <p className="">
                In an analysis of longitudinal prescription and diagnosis data,
                the US HIV diagnosis rate was{" "}
                <strong className="text-red">61%&nbsp;lower</strong> among
                adults who were prescribed PrEP from October 2019 to June 2021
                vs those not using PrEP in 2019
                <sup>1</sup>
              </p>
            </div>
          </div>
        </div>
      </Slab>
      <Slab>
        <div className="my-4">
          <Expander>
            <div className="flex flex-col md:flex-row items-center bg-grey-stone-15 text-grey-gilead md:py-9">
              <div className="w-full md:w-1/2 text-center text-footnote px-5 my-5 md:my-0">
                It was not a comparative analysis.
              </div>
              <Separator />
              <div className="w-full md:w-1/2 text-left text-footnote px-5 my-5 md:my-0">
                Methods included assumptions and projections about PWBP (people
                who may benefit from PrEP) as a group and may not reflect
                real-world rates of PrEP use.
              </div>
            </div>
          </Expander>
        </div>
      </Slab>
      <Slab>
        <div className="bg-grey-stone-30 mt-20">
          <div className="flex">
            <div className="w-1/8 shrink-0"></div>
            <div className="my-12">
              <p className="text-header-2 mb-5">
                Real-world PrEP use in the US*
                <sup className="daggers">†‡</sup> was also significantly
                associated with a{" "}
                <strong className="text-red">
                  decrease in new HIV diagnoses
                </strong>{" "}
                over a period of five years<sup>2</sup>
              </p>
              <p className="text-small">
                *Among people ≥<span className="mini-space">&nbsp;</span>13
                years of age indicated for PrEP from 2012-2016
              </p>
              <p className="text-small">
                <sup>†</sup>In 33 jurisdictions (32 states and District of
                Columbia) for which there are complete laboratory reporting of
                HIV viral suppression data for ≥
                <span className="mini-space">&nbsp;</span>3 years from 2013
                through 2015
              </p>
              <p className="text-small">
                <sup>‡</sup>Data shown for quintiles below do not represent
                estimated annual percent change (EAPC) for individual states
              </p>
            </div>
            <div className="w-1/8 shrink-0"></div>
          </div>
        </div>
        <div>
          <img
            src={Lockup}
            alt="A 2020 retrospective analysis of real-world data from 2012 to 2016, PrEP use showed a 2% increase over five years for states in the lowest PrEP use quintile and 20% decrease over five years for states in the highest PrEP use quintile2"
            className="hidden md:block"
          />
          <img
            src={LockupMobile}
            alt="A 2020 retrospective analysis of real-world data from 2012 to 2016, PrEP use showed a 2% increase over five years for states in the lowest PrEP use quintile and 20% decrease over five years for states in the highest PrEP use quintile2"
            className="block md:hidden"
          />
        </div>
      </Slab>
      <Slab>
        <div className="my-9">
          <Expander widthClass="md:w-6/6 lg:w-5/6">
            <div className="flex flex-col md:flex-row items-center bg-grey-stone-15 text-grey-gilead md:py-9">
              <div className="w-full md:w-1/4 text-left text-footnote px-5 my-5 md:my-0">
                The study modeled the EAPC in HIV diagnoses of persons ≥
                <span className="mini-space">&nbsp;</span>13 years of age for
                U.S. states grouped into quintiles of PrEP medication use from
                2012-2016.
              </div>
              <Separator />
              <div className="w-full md:w-1/4 text-left text-footnote px-5 my-5 md:my-0">
                Annual HIV diagnosis rates were used because HIV incidence data
                were not available for all states during the years studied. HIV
                diagnosis rates do not include people with undiagnosed HIV.
              </div>
              <Separator />
              <div className="w-full md:w-1/2 text-left text-footnote px-5 my-5 md:my-0">
                PrEP coverage per 100 in 2016:
                <br />
                Mean (SD) = 5.80 (2.84)
                <br />
                Range = 16.90 - 1.71
                <br />
                <br />
                Coverage is defined as persons prescribed PrEP during the prior
                year per estimated 100 persons with an indication for PrEP
              </div>
            </div>
          </Expander>
        </div>
      </Slab>

      <div className="bg-grey-light">
        <Slab>
          <div className="py-20 px-24">
            <p className="text-header-2">
              A large, real-world study examined PrEP use behaviors among
              ~14,000 adults<sup>*</sup> in the US who received PrEP care during
              a 6-year period<sup>†8</sup>
            </p>
            <p className="text-footnote">
              <sup>*</sup>The 2021 retrospective cohort analysis used electronic
              health records data from a Northern California integrated health
              system; PrEP prescriptions were filled for adults with an
              indication for PrEP.
            </p>
            <p className="text-footnote">†July 2012-March 2019; N=13,906</p>
          </div>
          <div className="mx-20 mb-12">
            <CalloutLeft>
              <div className="bg-white w-full py-12 px-14">
                <p className="text-header-2-alt">
                  <strong>
                    In this study, of those individuals engaged with PrEP care
                    at any point during the retrospective study period:
                  </strong>
                </p>
                <ul className="list-disc m-8">
                  <li className="text-header-2-alt">
                    <strong className="text-red">52.5%</strong> of those
                    initiating PrEP <strong>discontinued at least once</strong>{" "}
                    during the study period<sup>8</sup>
                  </li>
                  <li className="text-header-2-alt">
                    <strong className="text-red">60.2%</strong> of those who
                    discontinued{" "}
                    <strong>
                      reinitiated PrEP before the end of follow-up
                    </strong>
                    <sup>8</sup>
                  </li>
                </ul>
              </div>
            </CalloutLeft>
          </div>
          <div className=" bg-grey-stone-15">
            <div className="top-chevron w-40 h-16 mx-auto bg-grey-light"></div>
            <div className="pt-16 pb-20 px-24">
              <p className="text-header-2 text-center">
                The study also revealed HIV incidence rate estimates were
                highest among individuals who were{" "}
                <strong className="text-red">
                  prescribed PrEP and did not initiate
                </strong>
                , as well as those who{" "}
                <strong className="text-red">
                  discontinued and did not reinitiate PrEP
                </strong>
                <sup>8</sup>
              </p>
            </div>
          </div>
          <div className="bg-grey-stone-30 p-32">table</div>
          <div className="mt-8 mb-12 ml-12">
            <p className="text-footnote">
              aExcludes individuals who were diagnosed with HIV during the
              eligibility assessment at PrEP linkage.
            </p>
            <p className="text-footnote">
              <sup>b</sup>Persistent on PrEP refers to individuals who initiated
              and remained on PrEP throughout follow-up.
            </p>
            <p className="text-footnote">
              <sup>c</sup>One-sided 97.5% upper CI.
            </p>
          </div>
          <CalloutLeft>
            <div className="w-full md:w-3/8 chevron  bg-grey-stone-15">
              <p className="text-header-2 p-16">
                For some individuals, PrEP discontinuation may reflect a{" "}
                <strong className="text-red">
                  decrease in HIV risk and a deliberate decision.
                </strong>
              </p>
            </div>
            <div className="w-full md:w-5/8">
              <p className="text-body py-16 px-12">
                However, incident HIV cases observed among those who
                discontinued PrEP and did not reinitiate and the higher rates of
                discontinuation in key subgroups disproportionately affected by
                HIV suggest broader systemic barriers.<sup>8</sup>
              </p>
            </div>
          </CalloutLeft>
        </Slab>
      </div>
      <Slab>
        <div className="my-20">
          <Callout includeLine={false}>
            <p className="text-emphasis text-header-2 text-center pb-3 md:pb-5">
              Maintaining open access for all PrEP options is important to
              minimize barriers that may stand in the way of PrEP use
            </p>
            <p className="text-emphasis text-header-2 text-center pb-3 md:pb-5">
              According to the CDC, efforts must be further strengthened and
              expanded to reach all populations equitably
              <sup className="text-body not-italic">1</sup>
            </p>
          </Callout>
        </div>
      </Slab>
      <Slab>
        <div className="my-14">
          <CtaAidsVu tall>
            Explore local data on PrEP
            <br className="hidden md:inline lg:hidden xl:inline" /> use and new
            HIV diagnoses
          </CtaAidsVu>
        </div>
      </Slab>
      <Slab>
        <Endnotes
          references={[
            "Centers for Disease Control and Prevention. Expanding PrEP coverage in the United States to achieve EHE goals. Published October 17, 2023. Accessed December 31, 2023. https://www.cdc.gov/nchhstp/dear_colleague/2023/dcl-101723-prep-coverage.html",
            "Centers for Disease Control and Prevention. Estimated annual HIV infections in the U.S., 2010-2016; new HIV infections nationally, 2010-2016. Published 2019. Accessed December 31, 2023. https://www.cdc.gov/nchhstp/newsroom/docs/2019/HIV-Incidence-Data-Tables_508.pdf",
            <>
              Centers for Disease Control and Prevention. Estimated HIV
              incidence and prevalence in the United States, 2015–2019.{" "}
              <em>HIV Surveillance Supplemental Report 2021</em>;26(No. 1).
              Published May 2021. Accessed December 31, 2023.
              http://www.cdc.gov/ hiv/library/reports/hiv-surveillance.html
            </>,
            "Centers for Disease Control and Prevention. HIV Surveillance Report, 2020; vol. 33. Published May 2022. Accessed December 31, 2023. https://www.cdc.gov/hiv/library/reports/hiv-surveillance.html",
            "Centers for Disease Control and Prevention. HIV in the U.S. by the Numbers – 2021. Published September 29, 2023. Accessed December 31, 2023. https://www.cdc.gov/nchhstp/newsroom/fact-sheets/hiv/hiv-in-the-us-by-the-numbers.html",
            "Tao, L. et al. Real-world geographic variations of HIV diagnosis rates among individuals prescribed and not prescribed oral HIV pre-exposure prophylaxis regimens in the United States. Poster presented at IDWeek conference; October 19-23, 2022; Washington, D.C. Poster 1482.",
            <>
              Smith DK, Sullivan PS, Cadwell B, Waller L, Siddiqi A, Mera-Giler
              R, Hu X, Hoover KW, Harris NS, McCallister S. Evidence of an
              association of increases in pre-exposure prophylaxis coverage with
              decreases in human immunodeficiency virus diagnosis rates in the
              United States, 2012-2016. <em>Clin Infect Dis</em>. 2020;71(12):
              3144–3151. doi.org/10.1093/cid/ciz1229.
            </>,
            <>
              Hojilla JC, Hurley LB, Marcus JL, et al. Characterization of HIV
              preexposure prophylaxis use behaviors and HIV incidence among US
              Adults in an integrated health care system.{" "}
              <em>JAMA Network Open</em>. 2021;4(8):e2122692.
              doi:10.1001/jamanetworkopen.2021.22692.
            </>,
          ]}
        />
      </Slab>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Real-World PrEP Use</title>;
